<template>
  <div>
    <el-card>
      <div class="vg_button_group">
        <el-button type="primary" size="small" @click="submit">保存</el-button>
        <el-button size="small" @click="cancel">取消</el-button>
      </div>
      <el-form ref="userForm" size="mini" class="vg_partition" :model="addForm" :rules="rule">
        <el-row type="flex" justify="space-around" class="vd_rang vg_partition">
          <el-col :md="4">
            <el-form-item label="员工账号" prop="user_name">
              <!-- <el-input v-model="addForm.user_name" :disabled="true" placeholder="请选择对应员工">
                  <template slot="append">
                    <el-link type="primary" @click="openDialog()" >选择</el-link>
                  </template>
                </el-input> -->

              <select-input
                :selTableData="selTableData"
                :tableHeader="procTableHeader"
                @getSelTableRow="getSelTableRow"
                :inputVal="addForm.user_name"
                :disabled="disabled"
                :isShowCol="false"
                :rowSelect="rowSelect"
                placeholder="请选择员工姓名"
                title="员工列表"
              >
              </select-input>
            </el-form-item>
          </el-col>
          <el-col :md="4">
            <el-form-item label="员工姓名" prop="stff_name">
              <el-input placeholder="员工姓名" v-model="addForm.stff_name" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="4">
            <el-form-item label="员工编号" prop="stff_no">
              <el-input placeholder="员工编号" v-model="addForm.stff_no" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="4">
            <el-form-item label="小组" prop="dept_team_name">
              <el-input placeholder="员工所在小组" v-model="addForm.dept_team_name" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="默认工序" :rules="[{ required: true, trigger: 'change', message: '' }]">
              <el-select v-model="proc_defa" placeholder="请选择">
                <el-option v-for="item in proc_listc" :key="item.key" :label="item.label" :value="item.key"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item ref="transRef" label="工序账号分配(必选)" :rules="[{ required: true }]">
              <!-- <el-col :md="4" align="center">
        工序账号分配<span class="vg_deep_red"></span>：
      </el-col> -->
              <div style="text-align: center">
                <el-transfer
                  style="text-align: left; display: inline-block"
                  v-model="proc_listk"
                  :titles="['工序总表', '工序赋表']"
                  :button-texts="['去掉工序', '给予工序']"
                  :data="procList"
                  @change="tranChange"
                  :rules="[{ required: true }]"
                >
                </el-transfer>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { userAPI } from '@api/modules/user';
import { procAPI } from '@api/modules/proc';
import selectInput from '@/views/component/selectInput';
import { procTableHeader } from '@/views/component/tableHeader/staffTableHeader';
export default {
  name: 'procAdd',
  data() {
    return {
      addForm: {
        user_name: null,
        stff_name: null,
        stff_no: null,
        dept_team_name: null
      },
      selTableData: [],
      dialogVisible: false,
      procList: [
        { key: 'manu', label: '手工' },
        { key: 'insp', label: '检验' },
        { key: 'mase', label: '机缝' },
        { key: 'embr', label: '电绣' },
        { key: 'stam', label: '印花' },
        { key: 'lase', label: '激光' },
        { key: 'comp', label: '复合' },
        { key: 'open', label: '开版' },
        { key: 'stpr', label: '备料' },
        { key: 'emcd', label: '制卡' }
      ],
      proc_defa: '',
      proc_listk: [],
      proc_listc: [],
      rowSelect: [],
      procTableHeader: [],
      disabled: false,
      rule: {
        user_name: [{ required: true, trigger: 'change', message: ' ' }]
      },
      title: ' 提示：(双击表编对应数据即可选中人员)'
    };
  },
  methods: {
    getUserlist() {
      get(userAPI.getAllUsersV1)
        .then(res => {
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg);
          }
          this.selTableData = res.data.data;
        })

        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    openDialog() {
      this.dialogVisible = true;
    },
    dbClickJp(row) {
      this.addForm = row;
      this.dialogVisible = false;
    },
    submit() {
      if (!this.addForm.user_name) {
        return this.$message.warning('请选择账号');
      }

      if (this.proc_listk.length == 0) {
        return this.$message.warning('最少选择一个工序');
      }
      if (this.proc_defa == '') {
        return this.$message.warning('请选择一个默认工序');
      }

      this.$confirm('此操作将保存工序分配, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(res => {
          const addForm = {};
          addForm.proc_codes = this.proc_listk.join(',');
          addForm.user_id = this.addForm.user_id;
          addForm.proc_defa = this.proc_defa;
          post(procAPI.add_proc, addForm)
            .then(res => {
              if (res.data.code === 0) {
                this.proc_listk = [];
                this.proc_listc = [];
                this.proc_defa = '';
                this.$message.success(res.data.msg);
                this.$refs.userForm.resetFields();
                this.getUserlist();
                this.jump(`/proc_edit?id=${res.data.data.form_id}`);
              } else if (res.data.code === 7) {
                this.$message.error('该员工账号已分配');
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.getUserlist();
            });
        })
        .catch(res => {
          this.$message.info('已取消保存');
        });
    },
    cancel() {
      this.$confirm('是否取消新增', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.jump('/proc_list');
        })
        .catch(() => {});
    },

    tranChange(val) {
      this.proc_listc = [];
      for (let i in this.procList) {
        for (let j in val) {
          if (this.procList[i].key == val[j]) {
            this.proc_listc.push(this.procList[i]);
          }
        }
      }
      if (val.indexOf(this.proc_defa) == -1) {
        this.proc_defa = '';
      }
      if (val.length != 0) {
        this.proc_defa = val[0];
      }
    },
    gouser() {
      this.jump('user_list');
    },
    getSelTableRow(val) {
      this.addForm = JSON.parse(JSON.stringify(val));
    }
  },
  created() {
    this.getUserlist();
    this.procTableHeader = procTableHeader;
  },
  components: {
    selectInput
  }
};
</script>

<style lang="scss" scoped>
.vd_tips {
  font-size: 12px;
  color: $color-info;
}
</style>
